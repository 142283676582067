<template>
	<div>
		<div class="search_box">
			<el-form :inline="true" :model="searchForm" class="demo-form-inline">
				<el-form-item class="formLabel" label="月份">
					<el-date-picker v-model="searchForm.startMonth" value-format="yyyy-MM" size="small" type="month" />
					<span style="margin: 0 5px">-</span>
					<el-date-picker v-model="searchForm.endMonth" value-format="yyyy-MM" size="small" type="month" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="_getList(1)" size="small" style="margin-left: 5px" icon="el-icon-search">查询</el-button>
					<el-button
						type="primary"
						@click="settingDialog = true"
						size="small"
						icon="el-icon-setting"
						style="margin-left: 15px"
						v-if="permissionControlBtns(pageName, 'Set')"
						>设置</el-button
					>
				</el-form-item>
				<el-form-item class="fr" style="margin-right: 0">
					<el-button
						type="primary"
						@click="$router.push({ name: 'Enterprisecostallocation' })"
						size="small"
						icon="el-icon-money"
						style="margin-left: 10px"
						v-if="permissionControlBtns(pageName, 'Config')"
						>成本配置</el-button
					>
				</el-form-item>
			</el-form>
			<el-table :data="tableList" v-loading="loadding" style="width: 100%">
				<el-table-column type="index" label="序号" :width="50" align="center"></el-table-column>
				<el-table-column label="月份" align="center" prop="month"></el-table-column>
				<el-table-column
					v-for="(col, i) in cols"
					:key="i"
					align="center"
					sortable
					:prop="col.id"
					:label="col.label"
					:width="150"
				></el-table-column>
				<el-table-column label="编辑人" align="center" prop="operatorName"></el-table-column>
				<el-table-column label="编辑时间" width="150" align="center" prop="updateTime">
					<template slot-scope="scope">
						{{ scope.row.updateTime.slice(0, 10) }}
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="130">
					<template slot-scope="scope">
						<el-button size="mini" type="text" @click="handleEdit(scope.row, 1)" v-if="permissionControlBtns(pageName, 'Edit')"
							>编辑</el-button
						>
						<el-button size="mini" type="text" @click="handleEdit(scope.row)" v-if="permissionControlBtns(pageName, 'Detail')"
							>详情</el-button
						>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				class="fr"
				style="margin: 20px 0"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="table.pageNo"
				:page-sizes="[20, 50, 100, 200]"
				:page-size="table.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="table.total"
			></el-pagination>
		</div>
		<!-- 薪酬调整弹框 -->
		<el-dialog title="展示列" :visible.sync="editDialog">
			<template slot="title">
				<span>张晓楠薪酬调整</span>
			</template>

			<div>
				<div class="table_name">调整前薪酬</div>
				<el-table border :data="tableList" style="width: 100%">
					<el-table-column label="基本工资" align="center">
						<template slot-scope>
							<span>3000</span>
						</template>
					</el-table-column>
					<el-table-column label="岗位工资" align="center">
						<template slot-scope>
							<span>4000</span>
						</template>
					</el-table-column>
					<el-table-column label="绩效工资" align="center">
						<template slot-scope>
							<span>1000</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="edit_table">
				<div class="table_name">调整后薪酬</div>
				<el-table border :data="tableList" style="width: 100%">
					<el-table-column label="基本工资" align="center">
						<template slot-scope>
							<el-input v-model="value" type="number"></el-input>
						</template>
					</el-table-column>
					<el-table-column label="岗位工资" align="center">
						<template slot-scope>
							<el-input v-model="value" type="number"></el-input>
						</template>
					</el-table-column>
					<el-table-column label="绩效工资" align="center">
						<template slot-scope>
							<el-input v-model="value" type="number"></el-input>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editDialog = false">取 消</el-button>
				<el-button type="primary" @click="editDialog = false">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="展示列" :visible.sync="settingDialog" center width="35%">
			<div>
				<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="_handleCheckAllChange">全选</el-checkbox>
				<div style="margin: 15px 0"></div>
				<el-checkbox-group v-model="configOptionsSelect" @change="_handleConfigOptions" style="padding-left: 30px">
					<el-checkbox
						v-for="(item, index) in configOptions"
						:label="item.typeName"
						:disabled="item.allowEdit == 0"
						style="margin: 10px"
						:key="index"
					></el-checkbox>
				</el-checkbox-group>
			</div>
			<!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cofirmTable">确 定</el-button>
      </span> -->
		</el-dialog>
	</div>
</template>
<script>
import { enterPrisecostList, costConfigList } from '@/api/remuneration';
export default {
	props: { pageName: {} },
	components: {},
	data() {
		return {
			loadding: false,
			editDialog: false,
			value: '',
			tableOp: [],
			cityOptions: [],
			configOptions: [],
			configOptionsSelect: [],
			configOptionsSelectAll: [],
			configOptionsSelectInit: [],
			cols: [],
			checkAll: false,
			isIndeterminate: true,
			settingDialog: false,
			searchForm: {
				startMonth: '',
				endMonth: ''
			},
			tableList: [],
			table: {
				total: 0,
				pageNo: 1,
				pageSize: 20
			}
		};
	},
	computed: {
		dict() {
			return this.$store.state.app.dict;
		},
		dictData() {
			const Arr = {
				sexList: [],
				sorceList: [],
				experiencelist: [],
				educationList: []
			};

			Array.isArray(this.dict) &&
				this.dict.forEach((item) => {
					if (item.groupId === 'sex') {
						Arr.sexList.push({ value: item.dictId, label: item.dictName });
					}
				});
			return Arr;
		}
	},
	mounted() {
		this._getCongfig();
		this._getList();
	},
	methods: {
		_getCongfig() {
			costConfigList({}).then((res) => {
				delete res._responseStatusCode;
				this.configOptions = res;
				const _list = [];

				const _lis2 = [];

				res.forEach((item) => (item.allowEdit == 0 ? _list.push(item.typeName) : ''));
				res.forEach((item) => _lis2.push(item.typeName));
				this.configOptionsSelect = _list;
				this.configOptionsSelectInit = _list;
				this.configOptionsSelectAll = _lis2;
				this.$nextTick(() => {
					this._cofirmTable();
				});
			});
		},
		_cofirmTable() {
			this.cols = [];
			this.configOptionsSelect.map((it1) => {
				this.configOptions.map((it) => {
					if (it1 == it.typeName) {
						this.cols.push({ id: `config${it.id}`, label: it.typeName });
					}
				});
				this.$forceUpdate();
			});
		},
		_getList(page) {
			page ? (this.table.pageNo = page) : '';
			const params = {
				pageNo: this.table.pageNo,
				pageSize: this.table.pageSize,
				startMonth: this.searchForm.startMonth,
				endMonth: this.searchForm.endMonth
			};

			enterPrisecostList(params).then((res) => {
				res.list.map((it) => {
					this.configOptions.map((it1) => {
						it.payrollList.map((it2) => {
							if (it1.id == it2.id) {
								it[`config${it2.id}`] = it2.money;
							}
						});
					});
				});
				this.tableList = res.list;
				this.table.total = res.totalNum;
				this.$forceUpdate();
			});
		},
		// 薪酬调整
		handleEdit(row, type) {
			this.$router.push({
				name: 'PrisecostallocationInfo',
				query: { month: row.month, type }
			});
		},
		handleSizeChange(val) {
			this.table.pageSize = val;
			this._getList();
		},
		_handleCheckAllChange(val) {
			this.configOptionsSelect = val ? this.configOptionsSelectAll : this.configOptionsSelectInit;
			this.isIndeterminate = false;
			this._cofirmTable();
		},
		_handleConfigOptions() {
			this._cofirmTable();
		},
		handleCurrentChange(val) {
			this.table.pageNo = val;
			this._getList();
		}
	}
};
</script>
<style lang="scss" scoped>
.search_box {
	margin: 10px 0;
}
.table_name {
	font-size: 14px;
	padding-bottom: 12px;
	color: #484848;
}
.edit_table {
	padding-top: 30px;
	margin-top: 30px;
	border-top: 1px solid #e3e3e3;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
</style>
<style lang="scss">
.formLabel {
	.el-form-item__label {
		font-weight: normal;
	}
}
</style>
