<template>
	<div class="app-container">
		<el-tabs v-model="activeName" @tab-click="tabClick">
			<el-tab-pane label="工资设置" name="0" v-if="permissionControl('SalarySetting')">
				<wages-seeting pageName="SalarySetting"></wages-seeting>
			</el-tab-pane>
			<el-tab-pane label="企业成本管理" name="1" v-if="permissionControl('EnterpriseCostManagement')">
				<personal-remuneration pageName="EnterpriseCostManagement"></personal-remuneration>
			</el-tab-pane>
			<!--<el-tab-pane label="社保公积金扣款设置"
                   name="2">
        <cut-money></cut-money>
      </el-tab-pane>!-->
		</el-tabs>
	</div>
</template>

<script>
import wagesSeeting from './wagesSeeting.vue';
import personalRemuneration from './personalRemuneration.vue';
// import cutMoney from "./cutMoney.vue"
export default {
	components: {
		wagesSeeting,
		personalRemuneration
		// cutMoney
	},
	data() {
		return {
			activeName: '0'
		};
	},
	mounted() {
		const data = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

		if (data) {
			this.activeName = data.activeName;
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '0', key: 'SalarySetting' },
			{ name: '1', key: 'EnterpriseCostManagement' }
		]);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			`${this.$route.name}Data`,
			JSON.stringify({
				activeName: this.activeName
			})
		);
		next();
	},
	methods: {
		tabClick() {}
		// getSettingList () {
		//   this.$axios.post('/v1/payroll/config/list', {
		//     "request": {

		//     },
		//     "sign": ""
		//   }).then(res => {
		//     console.log('res', res);
		//   })
		// }
	}
};
</script>
<style lang="scss" scoped></style>
