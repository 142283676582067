<!--  -->
<template>
	<div class="home">
		<div class="header_btn">
			<el-button
				type="primary"
				style="float: right"
				size="small"
				icon="el-icon-edit"
				v-show="!edit"
				@click="edit = true"
				v-if="permissionControlBtns(pageName, 'Edit')"
			>
				编辑</el-button
			>
			<!--<el-button @click="cancle" v-show="edit">取 消</el-button>!-->
			<el-button type="primary" v-show="edit" @click="commit">完 成</el-button>
		</div>
		<div>
			<div class="row_box">
				<div class="row_lable">实发工资=</div>
				<div
					class="row_item"
					v-for="(item, index) in pageData"
					:key="index"
					:style="index == pageData.length - 1 ? 'margin:0 10px 12px 2px;' : ''"
				>
					<!-- <el-input
            v-model="item.operationType"
            placeholder="请选择"
            disabled
            v-show="index != 0"
            style="width:40px;margin:0 10px;"
          /> -->
					<!-- :disabled="edit ? (index != pageData.length - 1) : true" -->
					<el-select
						v-model="item.operationType"
						placeholder="请选择"
						:disabled="item.allowEdit == '0' || !edit"
						v-show="index != 0"
						style="width: 60px; margin: 0 10px"
					>
						<el-option key="+" value="+">+</el-option>
						<el-option key="-" value="-">-</el-option>
					</el-select>
					<div style="position: relative">
						<el-input
							v-model="item.typeName"
							style="width: 120px"
							maxlength="15"
							@blur="saveType(item, index)"
							:disabled="item.allowEdit == '0' || !edit"
						></el-input>
						<span
							class="el-icon-remove-outline remove_icon"
							v-show="item.allowEdit != 0 && edit"
							@click="removeparent(index, item)"
						></span>
					</div>
				</div>
				<!-- 点击添加一级子项 -->
				<span class="el-icon-circle-plus-outline add_input" style="margin-bottom: 10px" v-show="edit" @click="addparent"></span>
			</div>
			<!-- <div class="row_box">
      <div class="row_lable">本月工资=</div>
      <div>
        <el-input v-model="value1"
                  style="width:120px"></el-input>
        <input v-model="value"
      class="input_box">-->
		</div>
		<el-divider></el-divider>
		<div class="row_box" v-for="(item, index) in pageData" :key="index">
			<div class="row_lable" v-show="item.typeName">{{ item.typeName }}=</div>
			<div class="row_item" v-for="(child, childIndex) in item.children" :key="childIndex">
				<!-- <el-input v-model="item.operationType" placeholder="请选择" disabled v-show="childIndex != 0"
          style="width:40px;margin:0 10px;">
        </el-input> -->
				<el-select
					v-model="child.operationType"
					placeholder="请选择"
					:disabled="child.allowEdit == 0 || !edit"
					v-show="childIndex != 0"
					style="width: 60px; margin: 0 10px"
				>
					<el-option key="+" value="+">+</el-option>
					<el-option key="-" value="-">-</el-option>
				</el-select>
				<div style="position: relative">
					<el-input
						v-model="child.typeName"
						style="width: 120px"
						maxlength="15"
						:disabled="child.allowEdit == 0 || !edit"
						@blur="saveType(child, index, childIndex)"
					></el-input>
					<span
						class="el-icon-remove-outline remove_icon"
						v-show="child.allowEdit != 0 && edit"
						@click="removeInput(index, childIndex, child)"
					></span>
				</div>
			</div>
			<!-- 点击添加二级子项 -->
			<span
				class="el-icon-circle-plus-outline add_input"
				v-show="item.typeName && edit"
				style="margin: 0 0 10px 8px"
				@click="addInput(index, item)"
			></span>
		</div>
	</div>
</template>
<script>
import { payrollConfigSave, payrollConfigDelete } from '../../../api/remuneration';
export default {
	components: {},
	props: { pageName: {} },
	data() {
		return {
			value1: '基本工资',
			operationType: '+',
			pageDataCopy: [],
			pageData: [
				// {
				//   id: 1,
				//   typeName: "本月工资",
				//   operationType: 1,
				//   nodeType: 0,
				//   parentId: 0,
				//   payrollConfigSub: [
				//     {
				//       id: 2,
				//       typeName: "基本工资",
				//       operationType: 1,
				//       nodeType: 1,
				//       parentId: 0,
				//       payrollConfigSub: []
				//     },
				//     {
				//       id: 3,
				//       typeName: "岗位工资",
				//       operationType: 1,
				//       nodeType: 1,
				//       parentId: 0,
				//       payrollConfigSub: []
				//     },
				//     {
				//       id: 4,
				//       typeName: "绩效工资",
				//       operationType: 1,
				//       nodeType: 1,
				//       parentId: 0,
				//       payrollConfigSub: []
				//     }
				//   ]
				// }
			],
			edit: false
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		addparent() {
			if (!this.pageData[this.pageData.length - 1].typeName) {
				this.$message.warning('请先完善上一个配置信息');
				return false;
			}
			const nodeSort = this.pageData.length > 0 ? Number(this.pageData[this.pageData.length - 1].nodeSort) + 1 : 0;

			this.pageData.push({
				id: '',
				typeName: '',
				allowEdit: '1',
				operationType: '+',
				nodeType: 0,
				parentId: 0,
				nodeSort,
				children: []
			});
		},
		addInput(index, item) {
			if (this.pageData[index].children.length > 0 && !this.pageData[index].children[this.pageData[index].children.length - 1].typeName) {
				this.$message.warning('请先完善上一个配置信息');
				return false;
			}
			const nodeSort =
				this.pageData[index].children.length > 0
					? Number(this.pageData[index].children[this.pageData[index].children.length - 1].nodeSort) + 1
					: 0;

			this.pageData[index].children.push({
				id: '',
				typeName: '',
				operationType: '+',
				nodeType: 1,
				parentId: item.id,
				nodeSort,
				children: []
			});
		},
		removeparent(index, item) {
			if (item.id) {
				this.$confirm('是否确定删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					payrollConfigDelete(item).then((res) => {
						if (res._responseStatusCode == 0) {
							this.pageData.splice(index, 1);
						}
					});
				});
			} else {
				this.pageData.splice(index, 1);
			}
		},
		removeInput(index, childIndex, item) {
			if (item.id) {
				this.$confirm('是否确定删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					payrollConfigDelete(item).then((res) => {
						if (res._responseStatusCode == 0) {
							this.pageData[index].children.splice(childIndex, 1);
						}
					});
				});
			} else {
				this.pageData[index].children.splice(childIndex, 1);
			}
		},
		getData() {
			this.$axios
				.post('/v1/payroll/config/list', {
					request: {}
				})
				.then((res) => {
					if (res._responseStatusCode == 0) {
						delete res._responseStatusCode;
						this.pageData = res;
						this.pageDataCopy = JSON.parse(JSON.stringify(res));
					}
				});
		},
		saveType(item, index, childIndex) {
			if (item.typeName) {
				payrollConfigSave(item).then((res) => {
					if (res._responseStatusCode == 0) {
						if (childIndex != undefined) {
							this.pageData[index].children[childIndex].id = res.id;
						} else {
							this.pageData[index].id = res.id;
						}
					} else if (childIndex != undefined) {
						this.pageData[index].children.splice(childIndex, 1);
					} else {
						this.pageData.splice(index, 1);
					}
				});
			}
		},
		commit() {
			let status = false;

			this.$confirm('是否确定提交?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.pageData.forEach((it) => {
					if (!status && !it.typeName) {
						this.$message.warning('检测到有薪酬分类为空,请完善后再点击完成');
						status = true;
					} else {
						it.children.forEach((it1) => {
							if (!this.edit && !it1.typeName) {
								this.$message.warning('检测到有薪酬分类为空,请完善后再点击完成');
								status = true;
							}
						});
					}
				});
				this.edit = status;
			});
		},
		cancle() {
			this.pageData = JSON.parse(JSON.stringify(this.pageDataCopy));
			this.edit = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.header_btn {
	padding: 12px;
	display: flex;
	justify-content: flex-end;
}

.row_box {
	margin: 12px 0;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	.row_lable {
		padding-right: 6px;
		margin-bottom: 15px;
		flex-shrink: 0;
	}

	.row_item {
		display: flex;
		margin-bottom: 12px;
	}

	.remove_icon {
		color: #d9001b;
		position: absolute;
		top: -7px;
		right: -7px;
	}

	.add_input {
		padding: 0 12px 0 2px;
		color: #409eff;
		font-size: 24px;
		line-height: 40px;
	}
}

// .symbols_select {

// }
</style>
